.dark-theme {
  .CountryPolygon {
    fill: url(#Gradient);
  }
  .themeSwitch-Container {
    background: #000;

    .themecircle {
      border: 3px solid #fff;
      background: #000;
    }

    .themeText {
      color: #fff;
    }
  }
  .themeBackground {
    background: linear-gradient(180deg, #fff 1.3%, #000 31.88%);
    .error-msg {
      color: white !important;
    }
  }
  .graph_wrapper {
    background: #000;
    color: #fff;
    border: 1px solid #fff;
    .white-btn {
      background-position: -570px -100px;
      width: 28px;
      height: 27px;
    }
  }
  .graphcloseIcon {
    background-position: -571px -98px;
    width: 30px;
    height: 31px;
  }

  .inner-body-caro {
    background: #0d1927;
    border: 1px solid #fff;
  }
  .ongoing-incident-text,
  .currentstatus-txt,
  .opened-date,
  .impact-txt,
  .impact-desp {
    color: #fff;
  }
  .currentstatus-impact-section {
    border: 1px solid #fff;
  }
  .currentstatus-impact-section-text {
    color: #fff;
    border: 1px solid #fff;
  }
  .slick-dots li.slick-active button:before {
    color: #fff;
  }
  .slick-dots li button:before {
    color: #fff;
  }
  .prev {
    background-position: -449px -95px;
    width: 33px;
    height: 33px;
  }
  .next {
    background-position: -493px -94px;
    width: 32px;
    height: 34px;
  }
  .caro-body {
    background: #fff;
  }
  .filter-align {
    background: white;
  }
  .rightArrow-GAV {
    background-position: -101px -95px;
    width: 25px;
    height: 22px;
    display: inline-block;
  }
  .leftArrow-GAV {
    background-position: -69px -91px;
    width: 30px;
    height: 31px;
    display: inline-block;
  }

  .control {
    color: #555;
  }
  .brands {
    color: #000;
  }
  .brandsAll {
    color: #000;
  }
  .control_indicator {
    border: 1px solid #000;
  }
  .control-radio .control_indicator:after {
    background: #000;
  }
  .brands label:before {
    border: 2px solid #000;
  }
  .brands input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    border: solid #000;
    border-width: 0 3px 3px 0px;
    transform: rotate(45deg);
  }
  .brandsAll label:before {
    border: 2px solid #000;
  }
  .brandsAll input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    border: solid #000;
    border-width: 0 3px 3px 0px;
    transform: rotate(45deg);
  }
  .line-comp {
    border-left: 2px solid #000;
  }
  .filter-ddBox {
    border-right: 1px solid #000;
  }
  .resetWhiteText {
    color: #fff;
  }

  .available-cnt {
    .line-comp {
      border-left: 2px solid #fff;
    }
    .brands {
      color: #fff;
    }
    .brandsAll {
      color: #fff;
    }
    .control {
      color: #fff;
    }
    .control_indicator {
      border: 1px solid #fff;
    }
    .control-radio .control_indicator:after {
      background: #fff;
    }
    .brands label:before {
      border: 2px solid #fff;
    }

    .brands label:before {
      border: 2px solid #fff;
    }
    .brands input:checked + label:after {
      content: '';
      display: block;
      position: absolute;
      border: solid #fff;
      border-width: 0 3px 3px 0px;
      transform: rotate(45deg);
    }
    .brandsAll label:before {
      border: 2px solid #fff;
    }
    .brandsAll input:checked + label:after {
      content: '';
      display: block;
      position: absolute;
      border: solid #fff;
      border-width: 0 3px 3px 0px;
      transform: rotate(45deg);
    }
    .hm {
      background-position: -187px -18px;
      width: 26px;
      height: 17px;
    }
    .cos {
      background-position: -252px -18px;
      width: 47px;
      height: 16px;
      vertical-align: middle;
    }
    .monki {
      background-position: -465px -18px;
      width: 91px;
      height: 16px;
    }
    .stories {
      background-position: -595px -18px;
      width: 113px;
      height: 16px;
    }
    .arket {
      background-position: -748px -18px;
      width: 79px;
      height: 16px;
    }
    .weekday {
      background-position: -338px -18px;
      width: 87px;
      height: 16px;
    }
  }

  .hm {
    background-position: -187px -54px;
    width: 26px;
    height: 18px;
  }
  .cos {
    background-position: -252px -55px;
    width: 47px;
    height: 16px;
  }
  .weekday {
    background-position: -338px -55px;
    width: 87px;
    height: 16px;
  }
  .monki {
    background-position: -465px -55px;
    width: 91px;
    height: 16px;
  }
  .stories {
    background-position: -595px -55px;
    width: 113px;
    height: 16px;
  }
  .arket {
    background-position: -748px -55px;
    width: 79px;
    height: 16px;
  }

  .BlueBackground svg {
    background: #000;
  }
  select {
    color: #555;
  }
  .calender-cnt .react-calendar .react-calendar__navigation {
    background: #fff;
  }
  .stock-calendar .calender-cnt input#calendar-chkbox[type='checkbox'] {
    appearance: none;
    background-color: transparent;
    margin-right: 8px;
    font: inherit;
    color: #fff;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid #000;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }
  .stock-calendar .calender-cnt input#calendar-chkbox[type='checkbox']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #000;
    background-color: transparent;
  }
  .stock-calendar
    .calender-cnt
    .react-calendar__navigation
    .react-calendar__navigation__labelCustom {
    color: #000;
  }
  .customUnderlineTab {
    &.nav-tabs {
      .nav-item {
        .nav-link {
          color: #fff;

          &.active {
            border-bottom: 3px solid #fff;
          }
        }
      }
    }
  }
}
