.header.header-sticky {
  background: transparent;
  z-index: 999;
}
.circleIcon {
  background-color: #000;
  cursor: pointer;
}

.searchIcon {
  border: 2px solid #ffffff;
  border-radius: 50px;
  background-color: transparent !important;
}

.headericon-lg {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}

.headerhmlogo-lg {
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
}

.headerTitle-lg {
  position: absolute;
  top: 24px;
  line-height: 38px;
  /* identical to box height */
  color: #cc071e;
}

.headersearch {
  padding-right: 10px;
  margin-right: 0;
}

.search-control {
  height: 35px !important;
  border-radius: 30px;
  background-color: transparent;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.4);
  border-left-color: white;
}

.profileAvatar {
  color: #fff !important;
  background-color: #000;
  font-size: 14px;
  font-weight: 600;
}

.hmLogo {
  background: url('../images/svgs/hmLogo.svg') no-repeat 0 0;
  width: 140px;
  height: 30px;
}

.logoutSpriteIcon {
  background: url('../images/sprite/home_sprite.svg') no-repeat -212px -97px;
  width: 18px;
  height: 18px;
}

.header-Split {
  height: 100%;
  border-left: 0.1px solid black;
    margin: 0 1rem;
}

.notificationSpriteIcon {
  background-image: url('../images/svgs/spriteSVGs.svg');
  width: 32px;
  height: 32px;
  background-position: -80px -78px;
  display: inline-flex;
  align-items: center;
}

.bellPopNav {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50px;
  max-width: 202px;

  &.nav-tabs {
    --cui-nav-tabs-border-width: 0px;

    .nav-item {
      .nav-link {
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        color: #ffffff;
        cursor: pointer;
        margin: 5px;
        height: 28px;
        width: 91px;
        line-height: 13px;
        text-align: center;

        &.active {
          background: #243e5f;
          border-radius: 50px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
.themeSwitch-Container {
  display: flex;
  align-items: center;
  border: 2px solid #000;
  background: transparent;
  border-radius: 32px;
  min-width: 106px;
  height: 32px;
  padding: 0 6px;
  cursor: pointer;

  .themecircle {
    border-radius: 20px;
    border: 3px solid #000;
    background: transparent;
    width: 20px;
    height: 20px;
  }

  .themeText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #000;
    padding: 5px;
  }
}
.themeSwitch-Container.toggleStore.all {
  width: auto;
  border: 2px solid #00853d;

  .themecircle {
    border: 3px solid #00853d;
  }

  .themeText {
    font-size: 16px;
  }
}

.themeSwitch-Container.toggleStore.impact {
  width: auto;
  border: 2px solid #cf2021;

  .themecircle {
      border: 3px solid #cf2021;
  }
.themeText {
  font-size: 16px;
}
}

